<template>
  <div>

    <section class="detail-hero mb-4">
      <div class="container text-white">
        <div class="row d-flex justify-content-between align-items-center">
          <div class="col-lg-10 col-md-10 col-sm-12 detail-header-title flex-column">
            <h3>Tentang e-Jabatan</h3>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12 text-center detail-header-button">

          </div>
        </div>

        <div class="border-bottom mt-2 mb-4"></div>
      </div>
    </section>

    <section class="detail-content mb-5 mt-20">
      <div class="container">
        <div class="card border-0 shadow-lg">
          <div class="card-body" style="text-align:justify">
              <br>
            <div class="tentang-pre" style="text-align:justify; font-family: arial; font-size:12pt">
              <p>
                e-Jabatan adalah platform digital yang menyajikan informasi jabatan sebagai referensi bagi Pencari Kerja,
                Pemberi Kerja, dan pemangku kepentingan lainnya.
              </p>
              <p>
                Informasi jabatan disajikan dalam bentuk Kamus Jabatan Nasional (KJN) dan Klasifikasi Baku
                Jabatan Indonesia (KBJI). KJN menyajikan informasi jabatan sesuai Lapangan Usaha dari Klasifikasi
                Baku Lapangan Usaha Indonesia (KBLI) dan KBJI   menyajikan informasi jabatan sesuai dengan
                klasifikasi golongan yang mengacu pada International Standart Classification of Occupation (ISCO).
              </p>
              <p>
                e-Jabatan mengundang Anda untuk melakukan tinjauan dan memberikan usulan untuk memperbarui data
                jabatan dari informasi yang tersaji dengan
                data yang relevan.
              </p>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style>
  @import url('../assets/styles/detail.css');
  pre {
    text-align: justify;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .tentang-pre {
    padding: 0px 1vw;
  }
</style>
